<template>
  <a-drawer :visible="visible" width="50%" :maskClosable="false" @close="handleClose">
    <a-form-model :model="entity" @submit.prevent="handleSave">
      <a-form-model-item
        label="标题"
        prop="title"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.title" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="描述"
        prop="description"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-textarea
          v-model="entity.description"
          :autoSize="{ minRows: 2, maxRows: 6 }"
          allowClear
        />
      </a-form-model-item>
      <a-form-model-item
        label="发布时间"
        prop="publishTime"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-date-picker
          v-model="entity.publishTime"
          :showTime="true"
        ></y-date-picker>
      </a-form-model-item>
      <a-form-model-item
        label="配图"
        prop="properties.picture"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-upload
          v-model="entity.properties.picture"
          :maximumQuantity="1"
          :minimumQuantity="1"
          type="picture"
          accept="image/*"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
        prop="type"
        label="类型"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-radio v-model="entity.type" :type="'NotificationType'"></y-radio>
      </a-form-model-item>

      <a-form-model-item
        v-if="entity.type == enums.NotificationType.LINK.key"
        label="通告链接"
        prop="link"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.link" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        v-if="entity.type == enums.NotificationType.VIDEO.key"
        label="通告视频"
        prop="link"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-upload
          v-model="entity.link"
          :maximumQuantity="1"
          :minimumQuantity="1"
          accept="video/*"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
        v-if="entity.type == enums.NotificationType.DOCUMENT.key"
        label="通告内容类型"
        prop="document.format"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-radio
          v-model="entity.document.format"
          :type="'DocumentFormat'"
        ></y-radio>
      </a-form-model-item>
      <a-form-model-item
        v-if="
          entity.type == enums.NotificationType.DOCUMENT.key &&
          entity.document.format == enums.DocumentFormat.HTML.key
        "
        label="通告内容"
        prop="document.content"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        style="height: 430px !important"
      >
        <y-rich-text-editor
          v-model="entity.document.content"
        ></y-rich-text-editor>
      </a-form-model-item>
      <a-form-model-item
        v-if="
          entity.type == enums.NotificationType.DOCUMENT.key &&
          entity.document.format == enums.DocumentFormat.PDF.key
        "
        label="通告文件"
        prop="document.url"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-upload
          v-model="entity.document.url"
          :maximumQuantity="1"
          :minimumQuantity="1"
          accept="application/pdf"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
        prop="language"
        label="语言"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-radio v-model="entity.language" :type="'Language'"></y-radio>
      </a-form-model-item>
      <a-form-model-item
        label="排序"
        prop="sequence"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input-number
          v-model="entity.sequence"
          :defaultValue="1"
          :min="0"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item
        label="状态"
        prop="enabled"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-switch v-model="entity.enabled" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-space>
          <a-button htmlType="submit" :loading="loading" type="primary">
            保存
          </a-button>
          <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import Enums from "../../../enums";
import NotificationService from "../../../services/notification";

const defaultEntity = {
  properties: {},
  document: {},
};

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      enums: Enums,
      service: NotificationService,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
    };
  },
  watch: {
    target: {
      handler: function name(value) {
        this.entity = {
          ...defaultEntity,
          ...value,
        };

        if (!this.entity.properties) {
          this.entity.properties = {};
        }

        if (!this.entity.document) {
          this.entity.document = {};
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));

      this.$emit("close");

      this.$emit("update:visible", false);
      this.$emit("update:loading", false);
    },
    async handleSave() {
      try {
        this.$emit("update:loading", true);
        if (!this.entity.id) {
          await this.service.addEntity(this.entity);
        } else {
          await this.service.updateEntity(this.entity);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(this.entity));

        this.$emit("close");
        this.$emit("update:visible", false);
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>

<style>
</style>